import React from 'react';

import {graphql} from 'gatsby';
import {Link} from 'gatsby';
// import backButton from '../images/icons/back-button.svg';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton,
} from 'react-accessible-accordion';
import './faq.css';
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import backButton from '../images/icons/back-button-two.svg';

const Container = styled.div`
	margin: 0;
	padding: 0;
`;

const Header = styled.div`
	position: fixed;
	margin: 0;
	padding: 0;
	top: 0;
	width: 100%;
	display: block;
	background-color: #80e8fe;
	height: 100px;
`;

const backButtonCss = css`
	position: fixed;
	top: 4.5vh;
	left: 16px;
`;

const faqHeading = css`
	position: fixed;
	top: 3vh;
	left: 70px;
	color: white;
	font-family: Nunito;
	font-weight: 600;
	font-size: 1.25rem;
`;
const accordionItemButtonCss = css`
	font-family: Nunito;
	font-weight: 600;
	font-size: 0.9375rem;
	background-color: white;
	outline: 0px;
	padding: 28px 0px;
`;

const accordion = css`
	border: 0px;
	width: 85%;
	display: block;
	margin: auto;
	margin-top: 20vh;
`;

const accordionItemPanel = css`
	font-family: Nunito;
	color: #b2b2b2;
	font-size: 0.8125rem;
	padding-bottom: 16px;
	margin-top: -15px;
`;

export default function CustomizedExpansionPanels({data}) {
	return (
		<Container>
			<Header>
				<Link to="/">
					<img css={backButtonCss} src={backButton} alt="back button" />
				</Link>
				<p css={faqHeading}>FAQ</p>
			</Header>

			<Accordion allowZeroExpanded={true} css={accordion}>
				{data.allMarkdownRemark.nodes.map((node) => (
					<AccordionItem key={node.id}>
						<AccordionItemHeading>
							<AccordionItemButton css={accordionItemButtonCss}>
								{node.frontmatter.header}
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel
							css={accordionItemPanel}
							dangerouslySetInnerHTML={{__html: node.html}}
						></AccordionItemPanel>
					</AccordionItem>
				))}
			</Accordion>
		</Container>
	);
}

export const query = graphql`
	query FaqQuery {
		allMarkdownRemark(filter: {frontmatter: {title: {eq: "FAQ"}}}) {
			nodes {
				frontmatter {
					title
					header
				}
				html
				internal {
					content
				}
				id
			}
		}
	}
`;
